/**
*
*	Name:			IRANSansX Fonts
*	Version:			3
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		Apr 22, 2021
*	Updated on:		Jul 12, 2023
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونتایران سنس Xا یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
IRANSansX fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
------------------------------------------------------------------------------------- -
*	
**/

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: bold;
	src: url('woff/IRANSansX-Bold.woff') format('woff'),
	url('woff2/IRANSansX-Bold.woff2') format('woff2');
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: normal;
	src: url('woff/IRANSansX-Regular.woff') format('woff'),
	url('woff2/IRANSansX-Regular.woff2') format('woff2');
}


