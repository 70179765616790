.input {
    padding: 16px 32px;

    outline: none;
    border-radius: 8px;
    border: 1px solid #222222;

    font-family: Inter, sans-serif;
    font-size: 1rem;
    text-align: center;

    width: 226px;
    min-width: 226px;
}