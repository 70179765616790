.content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}

.content span {
    font-size: 1.5rem;
}

.content p {
    text-align: center;
}