.button {
    padding: 16px 32px;

    background-color: #FAFAFA;

    color: #222222;

    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-align: center;

    border-radius: 8px;
    border: 1px solid #222222;

    outline: none;

    min-width: 226px;
    cursor: pointer;

    transition: background-color 0.5s;
}

.button:hover, .button:active {
    background-color: #F2F2F3;
}