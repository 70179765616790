.content {
    background-color: #120E0D;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: start;

    height: 100%;
    min-height: 100vh;
    gap: 16px;

    color: #ffffff;
    font-family: "IRANSansX", sans-serif;

    padding-top: 160px;
}

.content * {
    z-index: 3;
}

.background {
    width: 120vw;
    height: 100vh;
    left: 0;
    top: 0;

    position: fixed;
    object-fit: cover;
    object-position: 0 0;

    opacity: 0.5;

    pointer-events:none;

    z-index: 2;
}

.header {
    width: 100%;
    min-height: 238px;
    max-height: 238px;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    z-index: 1;
}

.title {
    font-size: 40px;
    margin: 0;
    font-weight: bold;
}

.title span {
    color: #FFCD29;
}

.subtitle {
    font-size: 20px;
}

.info {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    gap: 4px;
    font-weight: 400;
    margin-top: 20px;
}

.info h5 {
    font-size: 16px;
    position: relative;
    margin: 0;
}

.info hr {
    border: 1px solid #FFCD29;
    border-bottom: 0;

    width: 280px;
}

.info .time {
    font-size: 12px;
}

.info .kids {
    color: #FF6666;
    font-size: 14px;
}

.address {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 4px;

    font-size: 14px;
    margin-top: 40px;
}

.address .english {
    font-size: 11px;
}

.navigationButton {
    margin-top: 16px;

    border-radius: 8px;
    border: 1px solid rgba(255, 205, 41, 1);

    font-family: IRANSansX, sans-serif;
    background-color: transparent;
    color: #FFCD29;
    padding: 12px 18px;

    text-decoration: none;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 10px;

    cursor: pointer;

    transition: background-color 0.2s;
}

.navigationButton:hover, .navigationButton:active {
    background-color: rgba(255, 255, 255, 0.1);
}

.navigationButton img {
    width: 14px;
    height: 14px;
    object-fit: contain;

}


.logo {
    margin: 30px 0;

    max-height: 62px;
    object-fit: contain;
}