.header {
    background-color: #CECED1;
    height: 390px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #222222;
    font-size: 1rem;
}

.header img {
    height: 100%;
    width: 100%;
    max-height: 100%;

    object-fit: cover;
}