.container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 10;
    backdrop-filter: brightness(0.3) blur(4px);


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal {
    z-index: 11;
    width: 75%;
    max-width: 350px;

    padding: 1rem;
    background-color: #FFFFFF;

    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    color: #222222;
    font-size: 1rem;

    text-align: center;
}

.modal input {
    width: calc(100% - 2rem);
    padding: 1rem;
    background-color: transparent;
    margin: 0.5rem 0
}

.button {
    width: 100%;
    border-radius: 8px;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;

    border-color: #222222;
    color: #222222;

    margin-top: 1rem;
    background-color: #B5E300;
}

.button:hover {
    background-color: #A0D900;
}


.error {
    font-size: 10px;
    color: #FF6666;
}