.voucherInfo {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 12px;
    z-index: 3;

    position: relative;

}

.hidden {
    visibility: hidden;
}

.qrContainer {
    background-color: rgba(109, 109, 116, 0.7);
    border-radius: 12px;
    padding: 16px;

    height: 150px;
    width: 150px;
}

.ticketNumber {
    color: #ffffff;
    font-size: 16px;
}