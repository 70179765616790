.container {
    padding: 1rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: start;
}

.footer {
    justify-self: end;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}