.content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;

    padding-top: 2rem;
    gap: 16px;
}

.icon {
    width: 64px;
    height: 64px;
}

.validationForm {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.input {
    min-width: auto !important;
    width: calc(226px - 4rem) !important;
}

.submit {
    width: 226px;
    background-color: #B5E300 !important;
}

.submit:hover {
    background-color: #A0D900;
}
